.section-testmonials {
  display: flex;
  gap: 20px;
  align-items: center;
}

.column-testmonials {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 20%;
}

.section-details {
  border-left: 10px solid #f59c36;
  padding-left: 20px;
}

.name-section {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 14px;
  color: #f59c36 !important;
  line-height: 1.2em;
  margin: 0;
}

.title-section b {
  color: #650061;
}

.title-section {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.navigation-testmonials {
  display: flex;
  gap: 10px;
}

.swiper-testmonials {
  width: 80%;
  overflow: hidden;
  position: relative;
}

.swiper-button-testmonials-next,
.swiper-button-testmonials-prev {
  cursor: pointer;
}

@media (max-width: 768px) {
  .section-testmonials {
    flex-direction: column;
    align-items: flex-start;
  }

  .swiper-testmonials {
    width: 100%;
  }
}

.swiper-testmonials .swiper-slide {
  border-radius: 10px;
  background: #f59c36;
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  transform: scale(0.9) !important;
  filter: blur(1px) grayscale(0.5);
  transition: 0.2s;
}

.swiper-testmonials .swiper-slide * {
  margin: 0;
}

.swiper-testmonials .swiper-slide-active {
  filter: blur(0px) !important;
  transform: scale(1) !important;
}

.card-slide {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.head-slide {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-slide {
  display: flex;
  align-items: center;
  gap: 20px;
}

.title-slide h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

.title-slide h6 {
  color: #ffcb00;
  font-size: 14px;
  font-weight: 500;
}

.text-slide {
  color: #fff;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.swiper-button-testmonials-next,
.swiper-button-testmonials-prev {
  cursor: pointer;
}

@media (max-width: 768px) {
  .header-slide svg {
    width: 50px;
  }

  .title-slide h4 {
    font-size: 14px;
  }

  .swiper-testmonials .swiper-slide {
    padding: 20px;
  }
}
